import React from "react";
import { Window, WindowHeader } from "react95";
import { StaticImage } from "gatsby-plugin-image";

import Layout from "../components/layout";
import { links } from '../data/homepageLinks';
import { iconStyle } from "../styles";

const BugReportPage = () => {
  return (
    <Layout
      subtitle={'Home'}
      description={'Muscarian Softworks: independent game and interactive web developer since 2018'}
    >
      <div style={{ display: 'flex', flexDirection: 'column'}}>
        <Window style={{ maxWidth: '100%' }}>
          <WindowHeader as="h2" className="window-header-icon" style={{ padding: "16px" }}>
            <a className="link" target="_blank" href="https://docs.google.com/forms/d/e/1FAIpQLScvBZhGetx4NrMK0uQmGa9wsrRx2R5mt-Lyh8fT6duKlhHZgw/viewform?usp=sf_link">
            <StaticImage
              alt=""
              aria-hidden="true"
              src="../images/forms.png"
              style={iconStyle}
            />
            Click to send a bug report or other feedback
            </a>
          </WindowHeader>
        </Window>
        <ul className="social-icons-list">
            {links.map(link => (
              <li
                key={link.url}
              >
                <a className="social-icon-button" href={link.url} target="_blank" rel="noreferrer noopener">
                  {link.icon}
                </a>
              </li>
            ))}
          </ul>
      </div>
    </Layout>
  );
}

export default BugReportPage
