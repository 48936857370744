import React from "react";
import { StaticImage } from "gatsby-plugin-image";

export const links = [
  {
    text: "Itch.io Store",
    url: "https://muscarian.itch.io",
    description:
      "Here you will find released and upcoming games, demos or asset packs. For released work, I will post changelogs here. For updates on unreleased work, follow the Twitter.",
    icon: <StaticImage
      alt=""
      aria-hidden="true"
      className="icon-shadow"
      src="../images/itchio.png"
    />,
  },
  {
    text: "Twitter",
    url: "https://www.twitter.com/muscarian_games",
    description:
      "Used as a devlog and general blog, you will find both updates and rambling here. Follow & interact.",
    icon: <StaticImage
      alt=""
      aria-hidden="true"
      className="icon-shadow"
      src="../images/twitter.png"
    />,
  },
  {
    text: "YouTube",
    url: "https://www.youtube.com/channel/UCT5cUlZ_JBB6D1aCtIKp2yw",
    description:
      "Now that the official Twitch channel has passed away, you can find video updates here. Please like and subscribe. Please.",
    icon: <StaticImage
      alt=""
      aria-hidden="true"
      className="icon-shadow"
      src="../images/youtube.png"
    />,
  },
]
